@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');


spananimate {
    position: absolute;
    border-radius: 100vmax;
}

.top {
    top: 0;
    left: 0;
    width: 0;
    height: 5px;
    background: linear-gradient(
            90deg,
            transparent 50%,
            rgba(17, 207, 255, 0.5),
            rgb(17, 207, 255)

    );
}

.bottom {
    right: 0;
    bottom: 0;
    height: 5px;
    background: linear-gradient(
            90deg,
            rgb(17, 207, 255),
            rgba(17, 207, 255, 0.5),
            transparent 100%
    );
}

.right {
    top: 0;
    right: 0;
    width: 10px;
    height: 0;
    background: linear-gradient(
            180deg,
            transparent 0%,
            rgba(17, 207, 255, 0.5),
            rgb(17, 207, 255)
    );
}

.left {
    left: 0;
    bottom: 0;
    width: 10px;
    height: 0;
    background: linear-gradient(
            180deg,
            rgb(17, 207, 255),
            rgba(17, 207, 255, 0.5),
            transparent 70%
    );
}

.top {
    animation: animateTop 5s ease-in-out infinite;
}

.bottom {
    animation: animateBottom 5s ease-in-out infinite;
}

.right {
    animation: animateRight 5s ease-in-out infinite;
}

.left {
    animation: animateLeft 5s ease-in-out infinite;
}

@keyframes animateTop {
    25% {
        width: 100%;
        opacity: 1;
    }

    30%,
    100% {
        opacity: 0;
    }
}

@keyframes animateBottom {
    0%,
    50% {
        opacity: 0;
        width: 0;
    }

    75% {
        opacity: 1;
        width: 100%;
    }

    76%,
    100% {
        opacity: 0;
    }
}

@keyframes animateRight {
    0%,
    25% {
        opacity: 0;
        height: 0;
    }

    50% {
        opacity: 1;
        height: 100%;
    }

    55%,
    100% {
        height: 100%;
        opacity: 0;
    }
}

@keyframes animateLeft {
    0%,
    75% {
        opacity: 0;
        bottom: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        height: 100%;
    }
}



